<template>
    <div>
        <validation-observer ref="contactObserver" slim>
            <form class="row">
                <h3>{{ $t('shop.checkout.contact.info.title') }}</h3>

                <p>{{ $t('shop.checkout.contact.info.note') }}</p>

                <validation-provider name="email" mode="eager" v-slot="{ errors, classes }" slim>
                    <div :class="{'col-12':true, 'form-group':true, ...classes}">
                        <label for="email">{{ $t('shop.checkout.contact.info.email') }} <small>*</small></label>
                        <input id="email" type="email" :placeholder="$t('shop.checkout.contact.info.email_placeholder')" :class="{'form-control':true, 'sm-form-control':true, 'required':true, ...classes}" v-model="form.email.email" autocomplete="on" required/>
                        <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                </validation-provider>

                <h3>{{ $t('shop.checkout.contact.address.title') }}</h3>

                <p>{{ $t('shop.checkout.contact.address.note') }}</p>

                <TextField 
                    v-bind:value.sync="form.firstname" 
                    id="firstname" 
                    :label="$t('shop.checkout.contact.address.firstname')" 
                    :placeholder="$t('shop.checkout.contact.address.firstname_placeholder')" 
                    :rowClass="{'col-lg-6':true, 'form-group':true}" 
                    :inputClass="{'form-control':true, 'sm-form-control':true}" 
                    required 
                />

                <TextField 
                    v-bind:value.sync="form.lastname" 
                    id="lastname" 
                    :label="$t('shop.checkout.contact.address.lastname')" 
                    :placeholder="$t('shop.checkout.contact.address.lastname_placeholder')" 
                    :rowClass="{'col-lg-6':true, 'form-group':true}" 
                    :inputClass="{'form-control':true, 'sm-form-control':true}" 
                    required 
                />

                <div class="w-100"></div>

                <TextField 
                    v-bind:value.sync="form.companyName" 
                    id="companyname" 
                    :label="$t('shop.checkout.contact.address.company-name')" 
                    :placeholder="$t('shop.checkout.contact.address.company-name_placeholder')" 
                    :rowClass="{'col-12':true, 'form-group':true}" 
                    :inputClass="{'form-control':true, 'sm-form-control':true}" 
                />

                <Address 
                    @valid="onAddressValidate"
                    v-bind:value.sync="form.address" 
                    :options="addressOptions"
                    :labels="addressLabels"
                    :inputClass="{'form-control':true, 'sm-form-control':true}" 
                    :rowClass="{'col-12':true, 'form-group':true}" 
                    required
                />

                <Phone 
                    id="phone" 
                    v-bind:value.sync="form.phone" 
                    :label="$t('shop.checkout.contact.address.phone')" 
                    :rowClass="{'col-12':true, 'form-group':true}" 
                    :inputClass="{'form-control':true, 'sm-form-control':true}" 
                    required 
                />
            </form>
        </validation-observer>
    </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch} from '@fwk-node-modules/vue-property-decorator';
import Address from '@fwk-client/components/panels/input/Address.vue';
import Phone from '../../input/Phone.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import { ContactInterface } from '@fwk-client/modules/shop/helpers/contact';

@Component({
    components: {
        Address,
        Phone,
        TextField
    },
    computed: {},
})
export default class Contact extends Vue {

    emptyForm:any = {
        email: {
            type:"PERSONAL",
            email:""
        },
        firstname: "",
        lastname: "",
        companyName: "",
        address:{
            addressLines:[], 
            postalCode:"", 
            city:"", 
            countryCode:""
        },
        phone:{
            type:"BUSINESS",
            number:""
        }
    }

    form:ContactInterface = {
        email: {
            type:"PERSONAL",
            email:""
        },
        firstname: "",
        lastname: "",
        companyName: "",
        address:{
            addressLines:[], 
            postalCode:"", 
            city:"", 
            countryCode:""
        },
        phone:{
            type:"BUSINESS",
            number:""
        }
    }

    addressOptions = {
        showName: false,
        showNote: false,
        labelAsColumn: false,
        nbAddressLines: 2,
        listCountries: "WORLD"
    }

    get addressLabels() {
        return {
            addressLines:[
                this.$t("shop.checkout.contact.address.addressLine")
            ],
            addressLines_placeholder:[
                this.$t("shop.checkout.contact.address.addressLine1_placeholder"),
                this.$t("shop.checkout.contact.address.addressLine2_placeholder")
            ],
            postalCode:this.$t("shop.checkout.contact.address.postalCode"),
            postalCode_placeholder:this.$t("shop.checkout.contact.address.postalCode_placeholder"),
            city:this.$t("shop.checkout.contact.address.city"),
            city_placeholder:this.$t("shop.checkout.contact.address.city_placeholder"),
            country:this.$t("shop.checkout.contact.address.country"),
            country_select:this.$t("shop.checkout.contact.address.country_select")
        }
    }

    isValid:boolean = false;
    isAddressValid:boolean = false;

    created() {
        this.form = this.$shop.contact.getDefaultFromCart();
    }

    mounted() {
        this.validateContact();
    }

    onAddressValidate(isValid:boolean) {
        // We update address in cart if it is valid or become invalid
        if(isValid || this.isAddressValid != isValid) {
            this.$shop.cart.updateContact(JSON.parse(JSON.stringify(this.form)));
        }
        this.isAddressValid = isValid;
    }

    @Watch('form', { deep: true })
    onChange(val: any, oldVal: any) {
        // We update the contact in store
        this.$shop.contact.update(JSON.parse(JSON.stringify(this.form)));
        // We check if it is valid or not
        Vue.nextTick(() => {
            // We validate the contact
            this.validateContact();
        });
    }

    validateContact() {
        if(this.$refs.contactObserver) {
            // @ts-ignore
            this.$refs.contactObserver.validate({silent:true}).then((isValid:boolean) => {
                if(isValid !== this.isValid) {
                    this.isValid = isValid;
                    this.$emit('valid', isValid);
                }
            })
        }
    }
    
}

</script>