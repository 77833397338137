<template>
    <div class="shippingPrice">
        <div v-for="(shippingPrice, shippingPriceIndex) in delivery.shippingPrices" :key="shippingPriceIndex + '-shippingPrice'" class="country">
            <span>{{ shippingPrice.countries.map((countryCode) => { return $t("COUNTRIES."+countryCode.toUpperCase()) }).join(", ") }}</span>
            <div v-for="(locationPrice, locationIndex) in shippingPrice.locationPrices" :key="shippingPriceIndex + '-' + locationIndex + '-location'" class="location">
                {{ locationPrice.postalCodeRegExps ? locationPrice.postalCodeRegExps.map((locationCode) => { return $t("shop.deliveries.shipping.locations."+locationCode); }).join(', ') : ""}}
                <ul v-if="locationPrice.pricePerItems">
                    <li v-for="(pricePerItem, indexPricePerItem) in locationPrice.pricePerItems" :key="shippingPriceIndex + '-' + locationIndex + '-' + indexPricePerItem + '-price'">
                        <span v-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems == undefined && pricePerItem.output.price.amount == 0" class="free">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.price.free-from', [pricePerItem.facts.minNumberOfItems]) }}</span>
                        <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems != undefined && pricePerItem.facts.minNumberOfItems == pricePerItem.facts.maxNumberOfItems" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.price.exact', [pricePerItem.facts.minNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
                        <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems != undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.price.between', [pricePerItem.facts.minNumberOfItems, pricePerItem.facts.maxNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
                        <span v-else-if="pricePerItem.facts.minNumberOfItems != undefined && pricePerItem.facts.maxNumberOfItems == undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.price.from', [pricePerItem.facts.minNumberOfItems, formatPriceAmount(pricePerItem.output.price)]) }}</span>
                        
                        <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount == undefined && pricePerItem.output.price.amount == 0" class="free">{{ $t('shop.deliveries.shipping.productsAmount.price.free-from', [formatPriceAmount(pricePerItem.facts.minProductsAmount)]) }}</span>
                        <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount != undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.deliveries.shipping.productsAmount.price..between', [formatPriceAmount(pricePerItem.facts.minProductsAmount), formatPriceAmount(pricePerItem.facts.maxProductsAmount), formatPriceAmount(pricePerItem.output.price)]) }}</span>
                        <span v-else-if="pricePerItem.facts.minProductsAmount != undefined && pricePerItem.facts.maxProductsAmount == undefined" :class="{'free' : pricePerItem.output.price.amount == 0}">{{ $t('shop.deliveries.shipping.productsAmount.price..from', [formatPriceAmount(pricePerItem.facts.minProductsAmount), formatPriceAmount(pricePerItem.output.price)]) }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style>

  .shippingPrice ul {
      padding-left: 0;
      margin-left: 1.75em;
      list-style-type:disc;
      margin-bottom:0px;
  }

  .shippingPrice ul li span.free {
      font-weight:600;
      color: #28a745
  }

  .shippingPrice {
      margin-top:5px;
      padding:10px;
      border: 0px;
      border-bottom: 1px solid rgba(0,0,0,0.075);
      box-shadow: 0 0 10px 0px rgba(0,0,0,.1);
  }

  .shippingPrice .country {
      margin-top:10px;
  }

  .shippingPrice .country:first-child {
      margin-top:0px;
  }

  .shippingPrice .country > span {
      display:block;
  }

  .shippingPrice .location {
      margin-left: 1.75em;
      margin-bottom: 5px;
  }

  .shippingPrice .location:last-child {
      margin-bottom:0px;
  }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';

@Component({
    components: {
        
    },
    computed: {},
})
export default class ShippingPrice extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly delivery: any

}

</script>