<template>
  <section class="checkout content">

    <div class="content-wrap">

      <div class="container clearfix">

        <p class="back-to-shop">
          <router-link :to="{ name : 'shop-products', params : { lang : currentLanguageCode, shopPath : $shopContent_path }}">
            <i class="icon-angle-left1"></i>
            {{ $t("shop.back-to-shop") }}
          </router-link>
        </p>

        <div v-if="$shop_cartProducts.length > 0" class="steps">
					<div class="row step">

            <div>
              <ul class="process-steps">
							  <li class="ui-tabs-active">
								  <span class="i-circled i-bordered mx-auto ui-tabs-anchor">1</span>
								  <h5>{{ $t("shop.checkout.review-cart.step") }}</h5>
							  </li>
						  </ul>
            </div>

            <div class="col">
              <Cart></Cart>
            </div>

					</div>

          <div class="row step">

            <div>
              <ul class="process-steps">
							  <li class="ui-tabs-active">
								  <span class="i-circled i-bordered mx-auto ui-tabs-anchor">2</span>
								  <h5>{{ $t("shop.checkout.contact.step") }}</h5>
							  </li>
						  </ul>
            </div>

            <div class="col">
              <Contact @valid="onContactValidate"></Contact>
            </div>

          </div>

          <div class="row step" v-if="cartProducts.length > 0">

            <div>
              <ul class="process-steps">
							  <li class="ui-tabs-active">
								  <span class="i-circled i-bordered mx-auto ui-tabs-anchor">3</span>
								  <h5>{{ getLocalizedText(labels.deliveryStep) }}</h5>
							  </li>
						  </ul>
            </div>

            <div class="col delivery-step">
              <Delivery @valid="onDeliveryValidate"></Delivery>
            </div>

          </div>

          <div class="row step">

            <div>
              <ul class="process-steps">
							  <li class="ui-tabs-active">
								  <span class="i-circled i-bordered mx-auto ui-tabs-anchor">
                    {{ cartProducts.length > 0 ? 4 : 3 }}
                  </span>
								  <h5>{{ getLocalizedText(labels.paymentStep) }}</h5>
							  </li>
						  </ul>
            </div>

            <div class="col payment-step">
              <Payment 
                :isContactValid="contactValid" 
                :isDeliveryValid="deliveryValid || (cartActivities.length > 0 && cartProducts.length == 0)"
              ></Payment>
            </div>

          </div>

				</div>

        <p v-if="$shop_cartProducts.length == 0">
          {{ $t("shop.checkout.empty-cart") }}
        </p>

        <p class="back-to-shop">
          <router-link :to="{ name : 'shop-products', params : { lang : currentLanguageCode, shopPath : $shopContent_path }}">
            <i class="icon-angle-left1"></i>
            {{ $t("shop.back-to-shop") }}
          </router-link>
        </p>
      </div>

    </div>

  </section><!-- #content end -->

</template>

<style>

  .delivery-step .pretty .state label:before, .delivery-step .pretty .state label:after,
  .payment-step .pretty .state label:before, .payment-step .pretty .state label:after {
      height:20px;
      width:20px;
      top:0px;
  }

  .delivery-step .pretty,
  .payment-step .pretty {
      line-height: 20px;
      display: block;
  }

  @media (max-width: 991.98px) {
    .delivery-step .pretty, 
    .payment-step .payment-item .pretty {
      white-space: normal;
      min-width: 180px;
    }
  }

  @media (max-width: 767.98px) {
    .delivery-step .pretty, 
    .payment-step .payment-item .pretty {
      white-space: normal;
      min-width: auto;
    }
  }

  .delivery-step .pretty:hover .state label,
  .payment-step .pretty:hover .state label {
      color: var(--secondary-color) !important;
  }

  .delivery-step .pretty .state label,
  .payment-step .pretty .state label {
      margin-left: 25px;
  }
</style>


<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { useCart } from '@root/src/client/composables/useCart'

import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { languagesTypes } from '@fwk-client/store/types';
import Cart from '../../panels/shop/checkout/Cart.vue';
import Delivery from '../../panels/shop/checkout/Delivery.vue';
import Contact from '../../panels/shop/checkout/Contact.vue';
import Payment from '../../panels/shop/checkout/Payment.vue';

export const shopDefaultLabels = {
  deliveryStep : {
    fr : "Livraison",
    de : "Lieferung",
    en : "Delivery"
  },
  paymentStep : {
    fr : "Paiement",
    de : "Zahlung",
    en : "Payment"
  }
}

@Component({
  components: { 
    Cart,
    Delivery,
    Contact,
    Payment
  },
  computed: {
    ...mapGetters({
      currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
    })
  }
})
export default class Checkout extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { cartProducts, cartActivities } = useCart({
          type: "shop"
      }, context);

    return { cartProducts, cartActivities }
  }

  contactValid = false;
  deliveryValid = false;

  labels = shopDefaultLabels;

  created() {
    this.$shop.cart.startCheckout();
  }
  
  destroyed() {}

  onDeliveryValidate(isValid:boolean) {
    this.deliveryValid = isValid;
  }

  onContactValidate(isValid:boolean) {
    this.contactValid = isValid;
  }
  
}
</script>