<template>

    <div>
        <div class="alert alert-danger" v-if="showMandatoryWarning">
            {{ $t("shop.deliveries.checkout.mandatory-warning") }}
		</div>
        <div class="alert alert-danger" v-if="availableTypes.length == 0">
            {{ $t("shop.deliveries.checkout.no-method-allowed") }}
		</div>

        <div v-if="availableTypes.length > 0">
        
            <DeliveryType v-bind:value.sync="selectedDeliveryType" v-for="type in availableTypes" :key="type" :type="type"></DeliveryType>
            
            <div class="row">
                <div class="col"></div>
                <div class="col-auto clearfix">
                    <div class="table-responsive">
                        <table class="table cart-recap amounts">
                            <tbody>
                            <tr class="cart_item">
                                <td class="cart-product-name">
                                    <strong>{{ $t("shop.deliveries.checkout.sub-total") }}</strong>
                                </td>
                                <td class="cart-product-name">
                                    <span class="amount">{{ formatPriceAmount($shop.cart.getDeliveryPrice()) }} </span>
                                </td>
                            </tr>
        
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
       
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import DeliveryType from './DeliveryType.vue';


@Component({
    components: {
        DeliveryType
    },
    computed: {},
})
export default class Delivery extends Vue {

    // The user must have selected a type of delivery available for the cart
    selectedDeliveryType:null|string = null;

    showMandatoryWarning = false;

    get availableTypes() {
        return Object.keys(this.$shop_cartPricesForDeliveryPerType);
    }

    mounted() {
        this.checkDefaultDeliveryType(this.$shop_cartIsOneDeliveryTypeAvailable);
        this.$emit('valid', this.selectedDeliveryType != null);
    }

    // We check when one method
    @Watch('$shop_cartIsOneDeliveryTypeAvailable')
    onOneDeliveryTypeAvailabilityChange(val: any, oldVal:any) {
        this.checkDefaultDeliveryType(val);
    }

    /**
     * This method select a default delivery method when there is one available and only one possible
     */
    checkDefaultDeliveryType(isOneDeliveryTypeAvailable:boolean) {
        if(isOneDeliveryTypeAvailable == true && this.availableTypes.length == 1 && this.selectedDeliveryType == null) {
            this.selectedDeliveryType = this.availableTypes[0];
        }
    }

    @Watch('selectedDeliveryType')
    onChange(val: any, oldVal: any) {
        // We update the shop store
        this.$shop.cart.selectTypeOfDelivery(val);
        // We emit the event for validation
        this.$emit('valid', val != null);
        // We check if we need to show a warning
        if(val == null && oldVal != null) {
            // We show a warning
            this.showMandatoryWarning = true;
        }
        else {
            // We hide the warning
            this.showMandatoryWarning = false;
        }
    }
}

</script>