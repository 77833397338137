<template>

    <div class="delivery">
        <span class="title" v-if="showTitle && delivery.title">
            {{ getLocalizedText(delivery.title) }}
        </span>
        <div class="alert alert-warning" v-if="nonEligibilityReason">
			<i class="icon-warning-sign"></i> {{ nonEligibilityReason }}
		</div>
        <span class="note" v-if="delivery.note">
            {{ getLocalizedText(delivery.note) }}
        </span>
        <div v-if="delivery.type == 'SHIPPING'">
            <span v-if="delivery.minimumNumberOfItems && delivery.minimumNumberOfItems > 1">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.minimumNumberOfItems', [delivery.minimumNumberOfItems]) }}<br/></span>
            <span v-if="delivery.numberOfItemsPerBox && delivery.numberOfItemsPerBox > 1">{{ $t('shop.deliveries.shipping.'+delivery.itemType.toLowerCase()+'.numberOfItemsPerBox', [delivery.numberOfItemsPerBox]) }}<br/></span>
        </div>
        <div v-if="delivery.type == 'PICKUP'" class="pickup">
            <span>{{ $t('shop.deliveries.pickup.address') }}</span>
            <address>
                <span v-if="delivery.address.name">{{delivery.address.name}}<br/></span>
                {{delivery.address.addressLines[0]}}<br/>
                {{delivery.address.postalCode}} {{delivery.address.city}}<br/>
            </address>
        </div>
        <div class="prices">
            {{ $t('shop.deliveries.checkout.price') }}<span>{{ formatPriceAmount(getDeliveryPrice()) }}</span>
        </div>
        <div class="details" v-if="delivery.type == 'SHIPPING'">
            <ShippingPriceCollapse :delivery="delivery" />
        </div>
    </div>
       
</template>

<style>

.delivery::after {
    content: '';
    display: block;
    margin: 10px 30px 20px 30px;
    border-top: 1px solid var(--secondary-color);
}

.delivery:last-child::after {
    display:none;
}


.delivery .title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: var(--secondary-color);
    margin-bottom:10px;
}

.delivery .warning, .delivery .note {
      display:block;
}

/* We add space between the delivery note and the specific mod information */
.delivery .note {
    margin-bottom: 1rem;
}

.delivery .warning, .delivery .prices, .delivery .details {
    margin-top:5px;
}

  .delivery .pickup {
      margin-top:5px;
  }

  .delivery .pickup > span {
      font-weight: 600;
  }

  .delivery .pickup address {
      margin-bottom:5px;
  }

  .delivery .prices {
      font-weight: 600;
  }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import ShippingPriceCollapse from '../delivery/ShippingPriceCollapse.vue';
import Address from '../order/Address.vue';

@Component({
    components: {
        ShippingPriceCollapse,
        Address
    },
    computed: {},
})
export default class DeliveryItem extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly delivery: any

    @Prop({
        type: Boolean,
        required: false,
        default: true
    }) readonly showTitle!: any


    get priceForDelivery() {
        return this.$shop_cartPriceForDeliveryCode(this.delivery.code);
    }

    get nonEligibilityReason() {
        var nonEligibilityReason = this.priceForDelivery.non_eligibility_reason;
        if(nonEligibilityReason) {
            // We check if we have a common eligibility reason
            var commonNonEligibilityReason = this.$shop_cartNonEligibilityReasonForDeliveryType(this.delivery.type);
            if(commonNonEligibilityReason) {
                return undefined;
            }
        }
        return nonEligibilityReason;
    }

    getDeliveryPrice() {
        if(this.priceForDelivery) {
            return this.priceForDelivery.price;
        }
        return null;
    }
}

</script>