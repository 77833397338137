<template>
    <div class="shippingPriceCollapse">
        <a href="javascript:void(0)" @click="onDeliveryDetailsClick" :class="{'opened':detailsOpened}">
                {{ $t("shop.deliveries.shipping.price-details") }} <i class="icon-chevron-down1"></i>
        </a>
        <Collapse v-model="detailsOpened" class="details">
            <div v-if="delivery.type == 'SHIPPING' && delivery.shippingPrices">
                <ShippingPrice :delivery="delivery" />
            </div>
        </Collapse>
    </div>
</template>

<style>

    .shippingPriceCollapse > a i:last-child {
        margin-left:5px;
        transition: 0.3s ease all;
    }

    .shippingPriceCollapse > a.opened i:last-child {
        transform: rotate(180deg);
    }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import ShippingPrice from './ShippingPrice.vue';
import Collapse from '@fwk-client/components/panels/bootstrap/Collapse.vue';

@Component({
    components: {
        ShippingPrice,
        Collapse
    },
    computed: {},
})
export default class ShippingPriceCollapse extends Vue {

    @Prop({
        type: Object,
        required: true
    }) readonly delivery: any

    @Prop({
        type: Boolean,
        required: false,
        default: false
    }) readonly opened: any

    detailsOpened:boolean = false;

    created() {
        this.detailsOpened = this.opened;
    }

    onDeliveryDetailsClick(evt:Event) {
        evt.preventDefault();
        this.detailsOpened = !this.detailsOpened;
    }

}

</script>