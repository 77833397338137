<template>

    <div class="row delivery-type">
        <div class="col-md-auto">
            <Radio 
                class="p-default p-round p-pulse" 
                color="success-o" 
                name="delivery" 
                :checked="isSelected" 
                :value="type" 
                :disabled="isDisabled" 
                v-on:change="onChange"
                :label="$shop.delivery.getDeliveryTypeTitle(methodsOfDelivery)">
            </Radio> 
        </div>
        <div class="col-md">
            <div class="alert alert-warning" v-if="nonEligibilityReason">
                <i class="icon-warning-sign"></i>
                <span>{{nonEligibilityReason}}</span>
            </div>
            <DeliveryItem v-for="(delivery,index) in methodsOfDelivery" :key="type+'-'+index" :delivery="delivery" :showTitle="methodsOfDelivery.length > 1"></DeliveryItem>
        </div>
    </div>
       
</template>

<style>

.delivery-type.row {
    margin-left:0px;
    margin-right:0px;
}

.delivery-type:first-child > div {
    border:0px;
}

.delivery-type > div {
    border-top: 2px solid #dee2e6;
    padding: 0.75rem;
}

@media (max-width: 767.98px) {
    .delivery-type > div:last-child {
        border-top:0px;
    }
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import DeliveryItem from './DeliveryItem.vue';
import Radio from '@fwk-client/components/panels/input/pretty-checkbox/Radio.vue';

@Component({
    components: {
        DeliveryItem,
        Radio
    },
    computed: {},
})
export default class DeliveryType extends Vue {

    @Prop({
        type: String,
        required: false
    }) readonly value!: string|null

    @Prop({
        type: String,
        required: true
    }) readonly type!: string

    input = this.type;

    get priceForDeliveryType() {
        return this.$shop_cartPriceForDeliveryType(this.type);
    }

    /**
     * This method returns the shared non eligibility reason common to all methods of delivery for the type.
     */
    get nonEligibilityReason() {
        return this.$shop_cartNonEligibilityReasonForDeliveryType(this.type);
    }

    get isSelected() {
        return this.value == this.type && !this.isDisabled
    }

    get isDisabled() {
        return !this.$shop_cartIsDeliveryTypeAvailable(this.type);
    }

    get methodsOfDelivery() {
        // We get the methods of delivery for the cart associated to the type
        var codes = this.$shop_cartPricesForDeliveryPerType[this.type].map((priceForDelivery:any) => priceForDelivery.code);
        return this.$shop_methodOfDeliveries.filter((delivery) => {
            return codes.indexOf(delivery.code) > -1
        })
    }

    getDeliveryPrice() {
        if(this.priceForDeliveryType) {
            return this.priceForDeliveryType.price;
        }
        return null;
    }

    onChange(val:number) {
        this.$emit('update:value',val);
    }

    @Watch('isSelected') 
    onSelectedChange(val: any, oldVal: any) {
        if(val == false && oldVal == true && this.isDisabled) {
            this.$emit('update:value',null);
        }
    }
}

</script>